import React from "react"
import { Link } from "gatsby"

import Routes from "../routes"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Cross from "../images/cross.jpg"
import { useImpression } from "../analytics/utils"

const Christianity = ({ location }) => {
  useImpression("Christianity")
  return (
    <Layout location={location}>
      <Seo
        title="Christianity Beliefs and Origins"
        description="Christianity has represented over 30% of the world's population. What are Christian beliefs and origins? Let's learn about Jesus Christ and the Bible."
      />
      <h1>Christianity</h1>
      <img
        src={Cross}
        alt="A Christian cross on a hill"
        width="300"
        style={{ float: "right", padding: 20 }}
      />
      <h2>What is Christianity?</h2>
      <p>
        It's an Abrahamic religion that revoles around the life and teachings of
        Jesus Christ.
      </p>
      <h2>What is the Bible?</h2>
      <p>
        The <Link to={Routes.BIBLE}>Bible</Link> is a collection of scriptures,
        or sacred texts. Some people believe that the Bible has been partially
        written, or at least heavily influenced by God.
      </p>
      <p>
        The Bible is one of the best-selling books of all time. It's been
        translated into every single major language of the world. You might be
        familiar with some of these versions.
      </p>
      <ul>
        <li>New international Version</li>
        <li>King James Version</li>
        <li>New Living Translation</li>
        <li>New King James Version</li>
        <li>English Standard Version</li>
      </ul>

      <h2>Who is Jesus?</h2>
      <p>
        Well, Christians believe that he is the son of God. Aside from that
        title, he also goes by Messiah, Christ, Lamb of God, so on.
      </p>
      <p>
        He was born of a virgin named Mary, with the help of the Holy Spirit. He
        supposedly performed miracles and is accredited for founding the
        Christian Church.
      </p>
      <p>
        Jesus was crucified and died on the cross, a symbol that has become
        paramount to the faith. In fact, most churches feature at least one.
        Often Jesus is portrayed as visibly tortured and wearing a crown of
        thornes. One wonders how the recurring exposure to such a thing impacts
        the developing minds of children and young adults.
      </p>

      <hr />
      <p>
        Be sure to check out about what it means to be{" "}
        <Link to={Routes.AGNOSTIC}>Agnostic</Link>, or to{" "}
        <Link to={Routes.BIBLE}>learn about the Bible</Link>.
      </p>
    </Layout>
  )
}

export default Christianity
